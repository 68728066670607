@use "../../constants/colors" as *;

.transformY {
  transform: translateY(100%);
  visibility: hidden;
}

.noTranformY {
  transform: translateY(0);
  visibility: visible;
}

.nav {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $light-400;
  border-top: 3px solid $light-500;
  transition:
    transform 0.3s,
    visibility 0.3s;
  &__box {
    padding: 0.8em 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__linkBox {
    margin: 0 1em;
  }

  &__link {
    display: none;
    padding: 0.8em 1em;
    text-decoration: none;
    border-radius: 24px;
    font-size: 1.8rem;
    color: $light-900;
    font-weight: bold;
    border: 2px solid transparent;
    transition:
      background-color 0.3s,
      border 0.3s,
      box-shadow 0.3s,
      transform 0.3s;
    &:hover {
      background-color: $light-200;
      border: 2px solid $light-600;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }
  }
  &__linkIcon {
    display: block;
    padding: 0.4em 0.8em;
    text-decoration: none;
    border-radius: 45%;
    font-size: 2rem;
    color: $light-900;
    font-weight: bold;
    border: 2px solid transparent;
    transition:
      background-color 0.3s,
      border 0.3s,
      box-shadow 0.3s,
      transform 0.3s;
    &:hover {
      background-color: $light-200;
      border: 2px solid $light-600;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }
  }

  &__active {
    background-color: $light-200;
    border: 2px solid $light-600;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }

  &__profile {
    display: none;
  }
}

@media (min-width: 375px) {
  .nav {
    &__linkIcon {
      font-size: 3rem;
    }
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .transformY {
    transform: translateY(-100%);
  }
  .nav {
    position: fixed;
    top: 0;
    left: 300px;
    right: 0px;
    bottom: unset;
    border-bottom: 3px solid $light-500;
    border-top: unset;
    &__link {
      display: block;
    }
    &__linkIcon {
      display: none;
    }
    &__profile {
      display: block;
    }
  }
}
