@use "../../constants/colors" as *;

.start {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__profileImg {
    height: 1em;
    width: 1em;
  }
}
.title {
  align-self: center;
  text-align: center;
  font-size: 10.6rem;
  color: $gao;
  font-weight: 600;
}

.row {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .title {
    font-size: 5rem;
  }
  .row {
    flex-direction: column;
  }
}
