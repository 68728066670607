@use "../../../constants/colors" as *;
.box {
  height: 0;
  margin: 0 auto;
  padding-top: 0.2em;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s;
  &__menuBox {
    list-style: none;
  }
  &__linkBox {
    margin: 0 0.2em;
    margin-bottom: 0.4em;
    border-radius: 24px;
    transition:
      background-color 0.3s 0.1s,
      border 0.3s;
    border: 2px solid transparent;
    cursor: pointer;
    position: static;
  }
  &__link {
    display: block;
    padding: 0.8em 1em;
    font-size: 1.8rem;
    text-decoration: none;
    color: $grey-800;
    border: 2px solid transparent;
    border-radius: 24px;
    &:hover {
      border: 2px solid $grey-500;
      background-color: $light-200;
      position: static;
    }
    &:visited {
      color: $grey-800;
    }
  }

  &__linkActive {
    border-radius: 24px;
    background-color: $light-200;
    border: 2px solid $grey-500;
    position: static;
  }
}

.open {
  height: auto;
  transform: scaleY(1);
  visibility: visible;
}
