@use "../../constants/colors" as *;

.popup {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self:  center;
    justify-self: center;
    top: 50% !important;
    background-color: $green-50;
    color: $green-900;
    padding: 16px 16px 16px 32px;
    border: 1px solid $green-900;
    border-radius: 16px;
    font-size: 2rem;
    transition: opacity 0.5s ease-in-out;
    z-index: 1000;
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $green-800;
        background-color: transparent;
        border: none;
        font-size: 1.8rem;
        font-weight: 300;
        margin-left: 64px;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background-color: $green-a50;
          color: $green-900;
          padding: 8px;
          border-radius: 8px;
        }
    }
}

.popup__error {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self:  center;
    justify-self: center;
    top: 50% !important;
    background-color: $error-100;
    color: $error-800;
    padding: 16px 16px 16px 32px;
    border: 1px solid $error-800;
    border-radius: 16px;
    font-size: 2rem;
    transition: opacity 0.5s ease-in-out;
    z-index: 1000;
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $error-800;
        background-color: transparent;
        border: none;
        font-size: 1.8rem;
        font-weight: 300;
        margin-left: 64px;
        padding: 8px;
        border-radius: 8px;
        &:hover {
            background-color: $error-200;
            color: $error-900;
            padding: 8px;
            border-radius: 8px;
          }
    }
}

@media (max-width: 600px) {
  .popup {
    top: 180px;
  }
}
