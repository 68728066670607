@use "../../constants/colors" as *;
.box {
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );

  &__cardBox {
    margin-bottom: 8rem;
    display: flex;
    width: 90%;
    border-radius: 12px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  }

  &__formBox {
    padding: 3em 1.6em;
    width: 100%;
    background-color: $light-200;
    border-radius: 12px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__formTitle {
    margin-bottom: 1em;
    font-size: 2.6rem;
    color: $light-800;
  }

  &__inputBox {
    display: flex;
    column-gap: 12px;
  }

  &__bottomContentBox {
    padding-top: 0.4em;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  &__buttonBox {
    margin-right: 1.6em;
  }

  &__button {
    position: relative;
    padding: 1em 2em;
    font-size: 1.6rem;
    font-weight: bold;
    color: $light-800;
    border-radius: 12px;
    background-color: $light-400;
    border: 2px solid $light-500;
    transition:
      background-color 0.3s,
      color 0.3s;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 101%;
      background-color: $light-700;
      z-index: -1;
      transform: scaleX(0);
      transition: transform 0.6s;
      transform-origin: left;
      content: "";
    }

    &:hover::before {
      transform: scaleX(100%);
    }
    &:hover span {
      animation: textAnimation 0.1s forwards;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__contentBox {
    display: none;
    padding: 3em 1.6em;
    width: 0%;
    background-color: $light-300;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &__info {
    margin-bottom: 1em;
    // text-align: center;
  }

  &__imgBox {
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__inputImgBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.4em;
  }

  &__inputImgHeader {
    margin-bottom: 0.4em;
    font-size: 1.6rem;
    font-weight: bold;
    color: $light-800;
  }

  &__inputImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
  }

  &__fileInputLabel {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    border-radius: 8px;
    width: fit-content;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-out;
    background-color: transparent;
    color: $light-800;
    font-family: "Montserrat", sans-serif;
    display: inline-block;

    &:hover {
      background-color: $light-400;
      color: $light-900;
    }
  }

  &__fileInput {
    display: none;
  }
  &__filesNames {
    max-height: 64px;
    max-width: 100%;
    overflow: hidden;
  }

  &__fileList {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-800;
    font-size: 1rem;
  }

  &__inputImgError {
    font-size: 1.4rem;
    color: $error-800;
  }
  &__deleteFiles {
    background-color: transparent;
    color: $error-800;
    padding: 6px 8px;
    border-radius: 8px;
    font-size: 1.6rem;
    border: none;
    font-family: "Montserrat", sans-serif;
    &:hover {
      background-color: $light-400;
    }
  }
}

@keyframes textAnimation {
  0% {
    color: $light-800;
  }

  100% {
    color: $light-400;
  }
}

.box__button span:nth-child(1) {
  animation-delay: 0.05s;
}
.box__button span:nth-child(2) {
  animation-delay: 0.075s;
}
.box__button span:nth-child(3) {
  animation-delay: 0.1s;
}
.box__button span:nth-child(4) {
  animation-delay: 0.125s;
}
.box__button span:nth-child(5) {
  animation-delay: 0.15s;
}
.box__button span:nth-child(6) {
  animation-delay: 0.175s;
}
.box__button span:nth-child(7) {
  animation-delay: 0.2s;
}
.box__button span:nth-child(8) {
  animation-delay: 0.225s;
}
.box__button span:nth-child(9) {
  animation-delay: 0.25s;
}
.box__button span:nth-child(10) {
  animation-delay: 0.275s;
}
.box__button span:nth-child(11) {
  animation-delay: 0.3s;
}
.box__button span:nth-child(12) {
  animation-delay: 0.325s;
}
.box__button span:nth-child(13) {
  animation-delay: 0.35s;
}
.box__button span:nth-child(14) {
  animation-delay: 0.375s;
}
.box__button span:nth-child(15) {
  animation-delay: 0.4s;
}
.box__button span:nth-child(16) {
  animation-delay: 0.4s;
}

@media (min-width: 768px) {
  .box {
    &__cardBox {
      width: 80%;
    }

    &__form {
      gap: 10px;
    }
    &__contentBox {
      display: block;
      width: 40%;
    }
    &__formBox {
      gap: 0;
      width: 60%;
      border-radius: 12px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

@media (min-width: 1200px) {
  .box__bottomContentBox {
    flex-direction: row;
    gap: 0;
  }
}

@media (min-width: 2560px) {
  .box {
    &__imgBox {
      margin: 0 auto;
      width: 70%;
      height: 70%;
    }
    &__img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 768px) {
  .box {
    &__buttonBox {
      margin-right: 0em;
    }
  }
}
