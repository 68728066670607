@use "../../constants/colors" as *;

.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle {
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, $gao 94%, #0000) top/6px 6px no-repeat,
    conic-gradient(#0000 30%, $gao);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
}
