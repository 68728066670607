$light-50: #f9f9fb;
$light-100: #f5f5f8;
$light-200: #eeeef4;
$light-300: #e4e4ee;
$light-400: #d4d4e4;
$light-500: #bbbbd4;
$light-600: #8484b1; //white text
$light-600-shadow: #8484b1e3; //white text
$light-700: #5d5d93; //white text
$light-700-shadow: #5d5d9357; //white text
$light-800: #484872; //white text
$light-900: #383858; //white text

$gao-light-50: #dddcfa;
$gao-light-100: #d2d1f9;
$gao-light-200: #c2c2f7;
$gao-light-300: #aeadf4;
$gao-light-400: #9392f0;
//$gao: #7270c5;
$gao: #7270c5;
$gao-light-600: #4340e5;
$gao-light-700: #211edb;
$gao-light-800: #1c19ba;
$gao-light-900: #18169e;

$dark-50: #f9f8ff;
$dark-100: #f3f3ff;
$dark-200: #ededff;
$dark-300: #dfdff8;
$dark-400: #bbbbd4; //same as 'light-400'
$dark-500: #9d9db5;
$dark-600: #76768a; //white text
$dark-700: #606076; //white text
$dark-800: #414156; //white text
$dark-900: #202134; //white text

$error-50: #ffffff;
$error-100: #fff2e9;
$error-200: #ffc3b5;
$error-300: #ff9585;
$error-400: #ff7161;
$error-500: #ff5449;
$error-600: #f64a47;
$error-700: #e83e45;
$error-800: #db3041;
$error-900: #c7183f;
$error-a100: #ffad98;
$error-a200: #ff6b5f;
$error-a400: #ff3948;
$error-a700: #ff0049;

$grey-50: #ffffff;
$grey-100: #ffffff;
$grey-200: #f9f9f9;
$grey-300: #eaeaea;
$grey-400: #c6c6c6;
$grey-500: #a8a8a8;
$grey-600: #7e7e7e;
$grey-700: #6a6a6a;
$grey-800: #4b4b4b;
$grey-900: #2a2a2a;

$green-50: #caffc8;
$green-100: #7aff7a;
$green-200: #89e980;
$green-300: #60d857;
$green-400: #40cc37;
$green-500: #00bf00;
$green-600: #00b000;
$green-700: #009c00;
$green-800: #008b00;
$green-900: #006d00;
$green-a50: #baebb9;
$green-a100: #8fffaa;
$green-a200: #00ff8f;
$green-a400: #00f846;
$green-a700: #00d800;
