@use "../../constants/colors" as *;

.settings {
  height: fit-content;
  min-height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  color: $light-800;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
  font-size: 4rem;
  &__section {
    margin-top: 64px;
    font-size: 2.4rem;
    min-width: 30%;
  }
  &__subsection {
    font-weight: 600;
    font-size: 1.8rem;
    margin: 16x;
    display: flex;
    flex-direction: column;
    &__row {
      font-weight: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $light-100;
      border: 1px solid $light-800;
      border-radius: 16px;
      padding: 12px 12px 12px 24px;
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }
}

.password {
  font-weight: bold;
  position: relative;
  top: -3px;
}

@media (max-width: 992px) {
  .settings {
    font-size: 3.5rem;
  }
}
