@use "../../constants/colors" as *;
.box {
  height: fit-content;
  min-height: 100%;
  overflow: hidden;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-bottom: 3em;
  &__infoBox {
    margin-top: 1.4em;
    display: flex;
    flex-direction: column;
  }
  &__contentInfoBox {
    margin-top: 1.4em;
  }
  &__headerInfo {
    position: relative;
    padding: 0.6em 0;
    color: $light-800;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $light-600;
      content: "";
    }
  }
  &__info {
    padding-left: 0.4rem;
    margin-top: 0.8em;

    color: $light-800;
    text-align: justify;
  }

  &__textAreaInfo,
  &__ratingInfo {
    margin-bottom: 0.4em;
    color: $light-800;
  }

  &__textArea {
    padding: 0.5em 1em;
    width: 100%;
    height: 150px;
    font-size: 1.5rem;
    color: $light-800;
    border-radius: 2px;
    background-color: $light-50;
    border: 2px solid $light-500;
    resize: none;
    &:hover {
      color: $light-700;
      background-color: $light-300;
      border-color: $light-600;
    }

    &:focus {
      outline: 0px;
      color: $light-700;
      background-color: $light-100;
      border-color: $light-600;
    }
  }

  &__ratingsBox {
    position: relative;
  }

  &__textAreaBtn {
    display: block;
    margin-left: auto;
    padding: 0.5em 1em;
    border-radius: 8px;
    font-size: 1.5rem;
    font-weight: bold;
    color: $light-800;
    background-color: $light-400;
    border: 2px solid $light-500;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: $light-500;
    }
  }

  &__ratingInput {
    margin: 0.4em;
  }
  &__ratingLabel {
    color: $light-800;
    font-weight: bold;
  }

  &__commentSuccess {
    position: absolute;
    right: 0;
    top: 5%;
    color: green;
    font-size: 1.3rem;
  }
  &__commentError {
    position: absolute;
    right: 0;
    top: 5%;
    color: $error-800;
    font-size: 1.6rem;
  }

  &__userCommentsBox {
    margin-top: 1em;
  }

  &__userCommentsHeaderBox {
  }

  &__userCommentsHeader {
    color: $light-800;
    font-weight: bold;
    font-size: 1.8rem;
  }

  &__userComments {
    position: relative;
    min-height: 50px;
    margin-top: 1em;
    padding: 0.4em 0.4em;
  }

  &__userCommentBox {
    position: relative;
    padding-bottom: 1em;
    margin-top: 1em;
    &::after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $light-600;
      content: "";
    }
  }
  &__userCommentHeaderBox {
    display: flex;
  }
  &__userCommentImg {
    margin-right: 0.2em;
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  &__userCommentUsername {
    font-weight: bold;
    color: $light-800;
  }
  &__userCommentRatingBox {
    margin-top: 0.4em;
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  &__userCommentRating {
    width: 50%;
    color: gold;
  }
  &__userCommentDate {
    font-size: 1.4rem;
    color: $light-800;
  }
  &__userComment {
    margin-top: 0.4em;
    color: $light-800;
    font-size: 1.5rem;
    text-align: justify;
  }
}
.startsBox {
  display: flex;
  flex-direction: row-reverse;
}
.ratingStar {
  color: gray;
  font-size: 2rem;
  cursor: pointer;
}

.card {
  margin: 8rem auto;
  padding: 2rem;
  width: 95%;
  background-color: $light-200;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  .topContentBox {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    .contentBox {
      .cardName {
        color: $light-800;
      }
      & > p {
        font-weight: normal;
        margin-top: 0.4rem;
        padding: 0.4rem 0.6rem;
        display: inline-block;
        &::first-letter {
          text-transform: uppercase;
        }
        background-color: $light-400;
        border-radius: 12px;
      }
    }
  }
  .imgsBox {
    height: 300px;
  }
  .box__infoBox,
  .box__contentInfoBox {
    & > div > p {
      display: inline-block;
    }
    .box__info > * {
      display: inline-block;
      font-size: 3rem;
    }
  }
}

@media (min-width: 374px) {
  .box {
    &__commentSuccess {
      position: absolute;
      right: 0;
      bottom: 50%;
      color: green;
      font-size: medium;
    }
  }
}
@media (min-width: 992px) {
  .box {
    padding-bottom: 0em;
  }
  .card {
    padding: 4rem;
    width: 80%;
    margin-top: 13.2rem;
  }
}

@media (min-width: 1200px) {
  .card {
    .topContentBox {
      flex-direction: row;
      column-gap: 2rem;
      .imgsBox {
        width: 60%;
        height: 450px;
      }
      .contentBox {
        width: 40%;
      }
    }
  }
  .box__infoBox {
    width: 60%;
  }
}
