.page {
  min-height: 100vh;
  height: 100%;
  padding-bottom: 5em;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
}

@media (min-width: 768px) {
  .page {
    padding-bottom: 10em;
  }
}
@media (min-width: 992px) {
  .page {
    padding-bottom: unset;
  }
}
