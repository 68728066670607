@use "constants/colors" as *;
.dialog {
  min-width: 30%;
  width: 90%;
  position: fixed;
  top: 50%;
  left: calc(50%);
  border-radius: 12px;
  border: 2px solid $light-600;
  box-shadow: 0 0 15px $light-600;
  background-color: $light-200;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scrollbar-color: $light-400 $light-200;
  &::backdrop {
    background-color: rgba(0, 0, 0, 0);
  }
}

dialog[open] {
  animation: fadeIn 0.3s ease-out forwards;
}

dialog[open]::backdrop {
  animation: backdropFadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    transform: translate(-50%, 0%);
    display: none;
  }

  100% {
    transform: translate(-50%, -50%);
    display: block;
  }
}

@keyframes backdropFadeIn {
  0% {
    background-color: rgb(0, 0, 0, 0);
  }

  100% {
    background-color: rgb(0, 0, 0, 0.65);
  }
}
@media (min-width: 574px) {
  .dialog {
    width: 60%;
  }
}
@media (min-width: 992px) {
  .dialog {
    width: 30%;
    left: calc(50% + 150px);
  }
}
