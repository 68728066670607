@use "../../constants/colors" as *;

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $light-100;
  background: linear-gradient(
    90deg,
    rgba(238, 238, 244, 1) 20%,
    rgba(228, 228, 238, 1) 40%,
    rgba(212, 212, 228, 1) 60%,
    rgba(187, 187, 212, 1) 80%
  );
}

.box {
  height: 740px;
  width: 1200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  &__heroImg {
    display: none;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url("../../assets/images/loginback.png");
    background-position: top;
    background-size: contain;
    z-index: 1;
  }
  &__brandName {
    margin-bottom: 1em;
    font-size: 3.8rem;
    text-align: center;
    letter-spacing: 1px;
    color: $light-800;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  }

  &__brandInfo {
    text-align: center;
    font-size: 3rem;
  }

  &__formBox {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 1em 3em;
    background: linear-gradient(
      90deg,
      rgba(238, 238, 244, 1) 20%,
      rgba(228, 228, 238, 1) 40%,
      rgba(212, 212, 228, 1) 60%,
      rgba(187, 187, 212, 1) 80%
    );
  }

  &__formName {
    margin-bottom: 1em;
    font-size: 2.4rem;
    text-align: center;
    color: $light-800;
    font-weight: bold;
  }

  &__nameBox {
    display: flex;
    gap: 0.4em;
  }

  &__formBtnBox {
    margin-top: 1em;
  }

  &__formBtn {
    position: relative;
    margin: 0.4em auto;
    padding: 0.6em 0;
    width: 100%;
    min-width: 10em;
    min-height: 2.5em;
    background-color: $light-500;
    font-size: 2rem;
    font-weight: bold;
    color: $light-900;
    border-radius: 8px;
    border: 2px solid $light-800;
    transition:
      background-color 0.3s,
      color 0.3s;
    cursor: pointer;
    z-index: 1;
    outline: 0px;
    text-decoration: none;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 101%;
      background-color: $light-800;
      z-index: -1;
      transform: scaleX(0);
      transition: transform 0.6s;
      transform-origin: left;
      content: "";
    }
    &:hover::before {
      transform: scaleX(100%);
    }
    &:hover span {
      animation: textAnimation 0.1s forwards;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__formNoAccountBox {
    display: flex;
    justify-content: center;
  }

  &__formNoAccount {
    margin-top: 0.2em;
    font-size: 1.6rem;
  }

  &__formNoAccountLink {
    text-decoration: none;
    color: $light-700;
    transition: color 0.3s;
    &:hover {
      color: $dark-900;
    }
  }

  &__errorMessage {
    position: absolute;
    bottom: -20px;
    font-size: 1.4rem;
    color: red;
  }
}

@keyframes textAnimation {
  0% {
    color: black;
  }

  100% {
    color: white;
  }
}

.box__formBtn span:nth-child(1) {
  animation-delay: 0.1s;
}
.box__formBtn span:nth-child(2) {
  animation-delay: 0.125s;
}
.box__formBtn span:nth-child(3) {
  animation-delay: 0.15s;
}
.box__formBtn span:nth-child(4) {
  animation-delay: 0.175s;
}
.box__formBtn span:nth-child(5) {
  animation-delay: 0.2s;
}
.box__formBtn span:nth-child(6) {
  animation-delay: 0.225s;
}
.box__formBtn span:nth-child(7) {
  animation-delay: 0.25s;
}
.box__formBtn span:nth-child(8) {
  animation-delay: 0.275s;
}
.box__formBtn span:nth-child(9) {
  animation-delay: 0.3s;
}
.box__formBtn span:nth-child(10) {
  animation-delay: 0.325s;
}
.box__formBtn span:nth-child(11) {
  animation-delay: 0.35s;
}
.box__formBtn span:nth-child(12) {
  animation-delay: 0.375s;
}
.box__formBtn span:nth-child(13) {
  animation-delay: 0.4s;
}
.box__formBtn span:nth-child(14) {
  animation-delay: 0.425s;
}

@media (min-width: 768px) {
  .box {
    flex-direction: row;
    &__heroImg {
      display: flex;
      width: 50%;
    }

    &__formBox {
      background: none;
    }
  }
}
