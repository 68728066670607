@use "../../../constants/colors" as *;

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 12px 16px;
  font-size: 2rem;
  font-weight: bold;
  max-width: 300px;
  cursor: pointer;
  border: "none";
  border-radius: 24px;
  color: $gao;
  margin: 1rem;
  transition: all 0.2s ease-out;
  color: $grey-800;
  background-color: $light-100;
  border: 2px solid transparent;
  &:hover {
    background-color: $light-400;
    //border: 2px solid $grey-700;
    position: static;
  }

  & > * {
    padding-right: 10px; // Apply margin to create spacing between children
    width: fit-content;
  }
}

.linkStyle {
  text-decoration: none;
}
