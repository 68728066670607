@use "../../../constants/colors" as *;

.column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 8px;
}

.scroll {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.sidebar {
  position: fixed;
  height: 100%;
  width: 300px;
  background-color: $light-100;
  transform: translateX(-100%);
  transition: all 0.3s ease-out;
  z-index: 10000;
  &__brandNameBox {
    position: relative;
    padding: 1em 0.4em 1.6em 0.4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $light-400;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 3px solid $light-500;
    // &::after {
    // 	display: flex;
    // 	position: absolute;
    // 	left: 28px;
    // 	bottom: -10px;
    // 	height: 10px;
    // 	width: 80%;
    // 	background-color: $light-400;
    // 	border-bottom-left-radius: 50%;
    // 	border-bottom-right-radius: 50%;
    // 	border-bottom: 3px solid $light-500;
    // 	content: "";
    // }
  }

  &__burgerBtnBox {
    display: flex;
    position: absolute;
    right: -35px;
    top: 100px;
    z-index: 2;
  }
  &__burgerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $light-600;
    border-radius: 50%;
    transition: transform 0.6s;
    border: 2px solid $light-600;
    // box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &:hover {
      background-color: $light-800;
    }
    z-index: 2;
  }

  &__burgerBtnIcon {
    font-size: 5rem;
    color: $light-100;
    transform: scale(105%);
    &:hover {
      color: $light-600;
    }
    z-index: 2;
  }

  &__brandName {
    font-size: 2.4rem;
    color: $gao;
  }

  &__profileBox {
    margin-top: 2em;
    padding: 0 0.8em;
    display: flex;
  }

  &__profileImgBox {
    position: relative;
    margin-right: 0.8em;
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border-radius: 50%;
      transition:
        background-color 0.3s,
        transform 0.3s;
      transform: scale(0);
      content: "";
    }
    &:hover::before {
      background-color: $light-700-shadow;
      transform: scale(1);
    }
  }

  &__profileImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__profileName {
    font-size: 2rem;
    color: $grey-800;
  }

  &__profileEmail {
    font-size: 1.4rem;
    color: $grey-800;
  }

  &__notLogged {
    font-size: 1.4rem;
    color: $grey-800;
    text-decoration: none;
    &:hover {
      color: #6f6deb;
    }
  }

  &__linksListBox {
    margin-top: 1em;
    padding: 1em 0.8em;
  }

  &__linkBox {
    margin: 0.8em 0;
  }

  &__link {
    padding: 0.8em;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 2rem;
    color: $grey-800;
    transition: background-color 0.3s;
    border-radius: 24px;
    border: 2px solid transparent;
    background-color: transparent;
    &:hover {
      background-color: $light-200;
      border: 2px solid $grey-500;
      position: static;
    }
    &:focus {
      background-color: $light-200;
      border: 2px solid $grey-500;
      position: static;
    }
    &:visited {
      color: $grey-800;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    padding: 0.8em;
    width: 100%;
    font-size: 2rem;
    font-family: "Montserrat";
    text-align: left;
    color: $grey-800;
    background-color: transparent;
    transition: background-color 0.3s;
    border-radius: 24px;
    border: 2px solid transparent;
    cursor: pointer;
    &:hover {
      background-color: $light-200;
      border: 2px solid $grey-500;
      position: static;
    }
    &:focus {
      background-color: $light-200;
      border: 2px solid $grey-500;
      position: static;
    }
  }

  &__linkActive {
    background-color: $light-200;
    border: 2px solid $grey-500;
    position: static;
  }

  &__linkIcon {
    margin-right: 0.4em;
    font-size: 3.4rem;
    color: $gao;
  }
  &__linkIconHand {
    margin-right: 0.4em;
    font-size: 3.4rem;
    color: $gao;
    transform: rotate(30deg);
  }

  &__iconDropdown {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }
  &__iconDropdownRotate {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.underline {
  margin: 0 auto;
  margin-top: 1em;
  height: 3px;
  width: 90%;
  background-color: $light-600;
  border-radius: 12px;
  border-bottom: 1px solid $light-700;
}

.linkActive {
  border: 2px solid $light-500;
  background-color: $light-400;
}

@media (min-width: 374px) {
  .sidebar {
    &__linksListBox {
    }
  }
}

@media (min-width: 992px) {
  .sidebar {
    transform: translateX(0);
    &__burgerBtnBox {
      display: none;
    }
    &__linksListBox {
    }
  }
}
