.wrapper {
  position: relative;
  flex: 1;
  height: 100vh;
}

@media (min-width: 992px) {
  .wrapper {
    margin: 0 0 0 300px;
  }
}

@media (min-width: 1700px) {
  .wrapper {
    margin: 0 0 0 300px;
  }
}
