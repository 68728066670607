@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-family: "Lato";
  background-color: #f9f9fb; /*light 50*/
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
}
