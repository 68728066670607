@use "../../constants/colors" as *;

.forgetPassword {
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: $light-300;
  // background: linear-gradient(180deg,
  //         rgba(238, 238, 244, 1) 0%,
  //         rgba(228, 228, 238, 1) 30%,
  //         rgba(212, 212, 228, 1) 70%);
  &__container {
    margin-top: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    min-width: 25%;
    height: fit-content;
    padding: 32px 32px 16px 32px;
    border: 1px solid $light-800;
    border-radius: 16px;
    background-color: $light-200;
  }
  &__description {
    color: $light-800;
    margin-bottom: 32px;
  }
  &__saveChangesButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 16px;
    margin-bottom: 16px;
    width: fit-content;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-out;
    background-color: transparent;
    color: $light-800;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    &:hover {
      background-color: $light-400;
      color: $light-900;
    }
  }
}
