@use "constants/colors" as *;
.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .heading {
    margin: 0.4em 0;
    font-size: 2.4rem;
  }
  .cardsBox {
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    padding: 0 0.8rem;
    margin-bottom: 2rem;
    width: 100%;
    .card {
      position: relative;
      display: flex;
      padding: 1.6rem;
      margin-top: 1rem;
      background-color: $light-200;
      border-radius: 12px;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
      .imgBox {
        margin-right: 1rem;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        .profileImg {
          width: 100%;
          height: 100%;

          box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
        }
      }
      .btnBox {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        .btnDelete,
        .btnEdit {
          padding: 0.2em 0.3em;
          font-size: 2rem;
          border-radius: 50%;
          transition:
            background-color 0.3s,
            color 0.3s;
          cursor: pointer;
        }
        .btnDelete {
          color: $error-400;
          background-color: $error-100;
          border: none;
          &:focus {
            outline: 2px solid $error-300;
          }
          &:hover {
            background-color: $error-400;
            color: $error-100;
          }
        }
        .btnEdit {
          color: $light-800;
          background-color: $light-400;
          border: none;
          &:focus {
            outline: 2px solid $light-600;
          }
          &:hover {
            background-color: $light-800;
            color: $light-400;
          }
        }
      }
      .infoBox {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        .info {
          font-size: 1.4rem;
        }
      }
    }
    .noPetsBox {
      margin: 3rem auto;
    }
  }
}

.dialog {
  width: 80%;
  position: fixed;
  top: 50%;
  left: calc(50%);
  border-radius: 12px;
  border: 2px solid $light-600;
  box-shadow: 0 0 15px $light-600;
  background-color: $light-200;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scrollbar-color: $light-400 $light-200;
  &::backdrop {
    background-color: rgba(0, 0, 0, 0);
  }
}

body:has(.dialog[open]) {
  overflow: hidden;
}

dialog[open] {
  animation: fadeIn 0.3s ease-out forwards;
}

dialog[open]::backdrop {
  animation: backdropFadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    transform: translate(-50%, 0%);
    display: none;
  }

  100% {
    transform: translate(-50%, -50%);
    display: block;
  }
}

@keyframes backdropFadeIn {
  0% {
    background-color: rgb(0, 0, 0, 0);
  }

  100% {
    background-color: rgb(0, 0, 0, 0.65);
  }
}

.dialogBox {
  padding: 6rem 2rem 4rem 2rem;
  width: 100%;
  height: fit-content;
  .closeBtn {
    position: fixed;
    top: 15px;
    right: 15px;
    display: block;
    margin-left: auto;
    margin-bottom: 0.5rem;
    padding: 1.2em;
    border-radius: 50%;
    border: none;
    background-color: $light-400;
    transition: background-color 0.3s;
    z-index: 10000;
    cursor: pointer;
    &:hover {
      background-color: $light-500;
    }
    &:focus {
      outline: 2px solid $light-500;
    }
  }

  .closeIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.6rem;
    color: $light-800;
  }
  .editBtn {
    display: block;
    margin-left: auto;
    padding: 0.8em 1em;
    font-size: 1.8rem;
    font-weight: bold;
    color: $light-800;
    border-radius: 12px;
    background-color: $light-400;
    border: 2px solid $light-500;
    transition:
      background-color 0.4s,
      color 0.4s;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;
    &:hover {
      background-color: $light-800;
      color: $light-400;
    }
    &:focus {
      outline: 2px solid $light-500;
      border: 2px solid transparent;
    }
  }
  .error {
    max-width: 40%;
    position: absolute;
    left: 20%;
    bottom: 8%;
    color: $error-800;
  }
}
@media (min-width: 576px) {
  .dialogBox {
    .error {
      right: 25%;
      bottom: 10%;
    }
  }
}
@media (min-width: 992px) {
  .dialog {
    width: 50%;
    left: calc(50% + 150px);
  }
}

@media (min-width: 1024px) {
  body:has(.dialog[open]) {
    overflow: hidden;
    // padding-right: 15px;
  }
}
