@use "constants/colors.scss" as *;
.box {
  position: relative;
  width: 100%;
  height: 100%;
  .imgSlider {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
  }
  .img {
    display: block;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    object-fit: cover;
    transition: translate 0.3s ease-in-out;
    border-radius: 8px;
  }
  .btn,
  .smallBtn {
    all: unset;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 1rem;
    cursor: pointer;
    z-index: 999;
    border-radius: 50%;
    transition: background-color 0.2s;
  }
  .btn:hover {
    background-color: $light-400;
  }
  .btn > * {
    display: block;
    stroke: $light-800;
    font-size: 2rem;
  }
  .smallBtn {
    padding: 0.4rem;
    & > * {
      display: block;
      stroke: $light-800;
      font-size: 1.2rem;
    }
  }
}
