@use "../../constants/colors" as *;

.box {
  height: fit-content;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
  &__buttonBox {
    margin-right: 1.8em;
  }
  &__button {
    position: relative;
    margin-bottom: 36px;
    margin: 0.4em auto;
    padding: 0.6em 1.6em;
    width: 100%;
    background-color: $light-500;
    font-size: 2rem;
    font-weight: bold;
    color: $light-900;
    border-radius: 8px;
    border: 2px solid $light-800;
    transition:
      background-color 0.3s,
      color 0.3s;
    cursor: pointer;
    z-index: 1;
    outline: 0px;
    text-decoration: none;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $light-800;
      z-index: -1;
      transform: scaleX(0);
      transition: transform 0.7s;
      transform-origin: left;
      content: "";
    }

    &:hover::before {
      transform: scaleX(100%);
    }
    &:hover span {
      animation: textAnimation 0.1s forwards;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@keyframes textAnimation {
  0% {
    color: $light-800;
  }

  100% {
    color: $light-400;
  }
}

@media (max-width: 992px) {
  .row {
    flex-direction: column;
  }
  .box__buttonBox {
    margin-right: 0px;
    margin-bottom: 3em;
  }
}

.box__button span:nth-child(1) {
  animation-delay: 0.05s;
}

.box__button span:nth-child(2) {
  animation-delay: 0.075s;
}

.box__button span:nth-child(3) {
  animation-delay: 0.1s;
}

.box__button span:nth-child(4) {
  animation-delay: 0.125s;
}

.box__button span:nth-child(5) {
  animation-delay: 0.15s;
}

.box__button span:nth-child(6) {
  animation-delay: 0.175s;
}

.box__button span:nth-child(7) {
  animation-delay: 0.2s;
}

.box__button span:nth-child(8) {
  animation-delay: 0.225s;
}

.box__button span:nth-child(9) {
  animation-delay: 0.25s;
}

.box__button span:nth-child(10) {
  animation-delay: 0.275s;
}

.box__button span:nth-child(11) {
  animation-delay: 0.3s;
}

.box__button span:nth-child(12) {
  animation-delay: 0.325s;
}

.box__button span:nth-child(13) {
  animation-delay: 0.35s;
}

.box__button span:nth-child(14) {
  animation-delay: 0.375s;
}

.box__button span:nth-child(15) {
  animation-delay: 0.4s;
}

.box__button span:nth-child(16) {
  animation-delay: 0.4s;
}
