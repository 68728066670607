@use "../../../constants/colors" as *;

.logout {
  display: flex;
  align-items: center;
  padding: 0.8em;
  width: 100%;
  font-size: 2rem;
  font-family: "Montserrat";
  text-align: left;
  color: $grey-800;
  background-color: $light-100;
  transition: background-color 0.3s;
  border-radius: 24px;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: $light-200;
    border: 2px solid $grey-500;
    position: static;
  }

  &__linkIcon {
    margin-right: 0.4em;
    font-size: 3.4rem;
    color: $gao;
  }
}
