@use "../../constants/colors" as *;
.boxDrop {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2em;
  width: 100%;
  &__dropBtn {
    padding: 0.6em 0.5em;
    width: 100%;
    font-size: 1.8rem;
    border-radius: 8px;
    border: 1px solid $light-500;
    background-color: $light-50;
    color: $light-700;
    text-align: left;
    transition: all 0.2s;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    &:hover {
      color: $light-700;
      background-color: $light-300;
      border-color: $light-600;
    }
    &:focus {
      outline: 0px;
      color: $light-700;
      background-color: $light-100;
      border-color: $light-800;
    }
    &__error {
      border-color: $error-800;
      &:hover {
        border-color: $error-800;
      }
      &:focus {
        border-color: $error-800;
      }
    }
  }

  .formLabelName {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    pointer-events: none;
    top: -10px; /* Adjust this value to position the label at the border */
    font-size: 1.2rem;
    color: $light-900;
    &:hover {
      color: $light-600;
    }
  }

  &__dropdownBox {
    width: 100%;
    height: fit-content;
    max-height: 250px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%) scaleY(0);
    transform-origin: top;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: $light-300 $light-50;
    border: 1px solid $light-500;
    visibility: hidden;
    transition:
      transform 0.2s ease-out,
      opacity 0.15s 0.05s;
    background-color: $light-50;
    z-index: 101;
    opacity: 0;
  }

  &__dropdownBtn {
    padding: 1em 0.5em;
    width: 100%;
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
    background-color: $light-50;
    color: $light-700;
    text-align: left;
    border: none;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      color: $light-700;
      background-color: $light-300;
      border-color: $light-600;
    }
    &:focus {
      outline: 0px;
      color: $light-700;
      background-color: $light-100;
      border-color: $light-800;
    }
  }
}

.errorMessage {
  position: absolute;
  bottom: -16px;
  left: 10px;
  color: $error-800;
  font-size: 1.2rem;
  margin-top: 0.4em;
}
