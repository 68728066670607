@use "../../constants/colors" as *;

.profile {
  position: relative;
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: $light-800;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 2em;
  }
  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
  }
  &__heroClassBtnBox {
    display: flex;
  }

  &__heroClassBtn {
    margin: 0em 0.6em;
    margin-bottom: 1em;
    padding: 1em 0.6em 1em 0.6em;
    color: $light-800;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    background-color: $light-400;
    border: 2px solid $light-500;
    border-radius: 12px;
    transition:
      background-color 0.3s,
      transform 0.3s;
    cursor: pointer;
    &:hover {
      transform: translateY(-10px) scale(1.05);
      background-color: $light-500;
    }
    &:focus {
      outline: 1px solid $light-500;
    }
  }
}

.boxedList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  li {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px 0;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1.6rem;
    font-weight: normal;

    &:hover {
      background-color: #e0e0e0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.profileBox {
  padding: 2em 1.4em;
  margin-bottom: 10rem;
  background-color: $light-200;
  border-radius: 12px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  min-width: 50%;
  &__pets {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10rem;
  }
}

.settings {
  height: fit-content;
  min-height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  color: $light-800;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
  font-size: 4rem;
  &__section {
    font-size: 2.4rem;
    min-width: 30%;
  }
  &__subsection {
    font-weight: 600;
    font-size: 1.8rem;
    margin: 16px;
    display: flex;
    flex-direction: column;
    &__row {
      font-weight: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $light-100;
      border: 1px solid $light-800;
      border-radius: 16px;
      padding: 12px 12px 12px 24px;
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.password {
  font-weight: bold;
  position: relative;
  top: -3px;
}
.sectionHeading {
  margin-bottom: 1em;
  font-size: 2rem;
}

@media (min-width: 768px) {
  .profileBox {
    &__pets {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 5rem;
    }
  }
}

@media (min-width: 1200px) {
  .profileBox {
    &__pets {
      width: 70%;
    }
  }
}
@media (max-width: 992px) {
  .settings {
    font-size: 3.5rem;
  }
}
