@use "../../constants/colors" as *;

.card {
  position: relative;
  border: 2px solid $light-600;
  border-radius: 12px;
  background-color: $light-200;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  &:hover {
    transform: scale(0.98);
    // transform: rotate(180deg) scale(0.98); xd
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
  &__titleBox {
    padding: 2em 1em;
    width: 100%;
    background-color: $light-400;
    border-bottom: 2px solid $light-600;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  &__title {
    font-size: 2rem;
    color: $light-800;
    text-align: center;
    text-transform: uppercase;
  }
  &__contentBox {
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  &__petInfoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__petImgBox {
    margin: 1em 0;
    width: 100%;
  }
  &__petImg {
    margin: 0 auto;
    display: block;
    width: 144px;
    height: 144px;
    border-radius: 50%;
  }

  &__petMoreInfoBox {
    display: flex;
    justify-content: center;
    column-gap: 0.4em;
    width: 100%;
  }

  &__infoBox {
    margin: 1em 0;
    display: flex;
    justify-content: center;
    column-gap: 0.4em;
  }

  &__singleInfoBox,
  &__singleFullInfoBox,
  &__singleInfoPetsBox {
    word-wrap: break-word;
    padding: 0.4em 0.8em;
    border: 1px solid $light-500;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
  }

  &__singleInfoBox {
    width: 49%;
    max-width: 50%;
  }
  &__singleFullInfoBox {
    width: 100%;
  }

  &__singleInfoPetsBox {
    width: 100%;
  }
  &__infoTitle {
    color: $light-800;
  }

  &__info {
    color: $light-800;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Liczba linii, które mają być wyświetlane */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  &__infoFull {
    color: $light-800;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Liczba linii, które mają być wyświetlane */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  &__descriptionBox {
    margin: 1em 0;
    padding: 0.4em 0.8em;
    border: 1px solid $light-500;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
  }
  &__additionalInfoBox {
    display: flex;
    align-items: center;
  }

  &__additionalInfoImg {
    margin-right: auto;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  &__additionalInfo {
    font-size: 1.4rem;
    color: $light-800;
  }
}

.sliderBox {
  height: 250px;
}
.imgBox {
  position: relative;
  padding: 1em;
  padding-bottom: unset;
  width: 100%;
  .img {
    width: 100%;
    height: 250px;
    border-radius: 8px;
  }
  .ratings {
    padding: 6px 6px;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: $light-600-shadow;
    border-radius: 0 4px;
    .average,
    .total {
      display: block;
      text-align: center;
      color: $light-50;
      z-index: 1;
    }
    .average {
      font-size: 2rem;
    }
    .total {
      font-size: 1.2rem;
    }
  }
}

@media (min-width: 576px) {
  .card {
    &__petImgBox {
      width: 100%;
    }
    &__petInfoBox {
      // flex-direction: row;
    }
    &__petMoreInfoBox {
      width: 100%;
      flex-direction: column;
      row-gap: 0.4em;
    }
  }

  .sliderBox {
    height: 350px;
  }
}

@media (min-width: 768px) {
  .sliderBox {
    height: 250px;
  }
}

@media (min-width: 1200px) {
  .sliderBox {
    height: 300px;
  }
}

@media (min-width: 1800px) {
  .sliderBox {
    height: 380px;
  }
}
