@use "../../constants/colors" as *;
.box {
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-bottom: 5em;
  &__sectionBox {
    margin: 2em 0;
    width: 90%;
  }
  &__cardsBox {
    position: relative;
    min-height: 300px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  &__filtersBox {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 1em;
  }
}

@media (min-width: 768px) {
  .box {
    &__cardsBox {
      grid-template-columns: 1fr 1fr;
    }
    &__sectionBox {
      width: 80%;
    }

    &__filtersBox {
      flex-direction: row;
      justify-content: center;
      width: 70%;
    }
  }
}
@media (min-width: 992px) {
  .box {
    padding-bottom: unset;
  }
}
