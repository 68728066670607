@use "../../../constants/colors" as *;

.popupButton {
  display: flex;
  align-self: center;
  border: none;
  background-color: transparent;
  color: $light-800;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 50%;
  font-size: 1.8rem;
  font-family: "Montserrat", sans-serif;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: $light-400;
    color: $light-900;
  }
  &__icon {
    height: 96px;
    width: 96px;
  }
  &__profileImg {
    position: relative;
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }
}

.changeName {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  /* Semi-transparent background */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  z-index: 100000;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background-color: $light-200;

    position: relative;
  }

  &__mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 150px;
    flex-direction: column;
    width: fit-content;
    min-width: 25%;
    height: fit-content;
    border: 1px solid $light-800;
    border-radius: 16px;
    background-color: $light-200;
  }

  &__topContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px 0 16px;
    margin-bottom: 32px;
    border-radius: 16px;
    background-color: $grey-200;
  }

  &__inputContainer {
    padding: 0 48px 0 48px;
  }

  &__saveChangesButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    width: fit-content;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-out;
    background-color: transparent;
    color: $light-800;
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
    &:hover {
      background-color: $light-400;
      color: $light-900;
    }
  }

  &__close {
    font-size: 48px;
    font-weight: 200;
    cursor: pointer;
  }

  &__icon {
    height: 128px;
    width: 128px;
  }

  &__profileImg {
    position: relative;
    display: block;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    margin-bottom: 8px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border-radius: 50%;
      transition:
        background-color 0.3s,
        transform 0.3s;
      transform: scale(0);
      content: "";
    }

    &:hover::before {
      background-color: $light-700-shadow;
      transform: scale(1);
    }
  }
  &__inputImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__fileInputLabel {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    width: fit-content;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-out;
    background-color: transparent;
    font-size: 1.6rem;
    color: $light-800;
    font-family: "Montserrat", sans-serif;
    display: inline-block;

    &:hover {
      background-color: $light-400;
      color: $light-900;
    }
  }

  &__fileInput {
    display: none;
  }

  &__deleteFiles {
    background-color: transparent;
    color: $error-800;
    padding: 12px 16px;
    border-radius: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 1.6rem;
    border: none;
    font-family: "Montserrat", sans-serif;
    &:hover {
      background-color: $light-400;
    }
  }
}

@media (max-width: 992px) {
  .changeName__mainContainer {
    left: 0px;
  }
}
