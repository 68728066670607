@use "../../../constants/colors" as *;

.formInputBox {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2em;
  width: 100%;
}

.formInputBoxError .formLabelName {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.6em;
  width: 100%;
  //top: calc(50% + 8px);
  /* Adjust to move label down when error message is present */
}

.formLabelName {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  pointer-events: none;
  color: $light-600;
  font-size: 1.6rem;
  &:hover {
    color: $light-600;
  }
}

.formInput {
  margin: 0 auto;
  padding: 0.6em 0.5em;
  width: 100%;
  font-size: 1.8rem;
  border-radius: 8px;
  border: 1px solid $light-500;
  background-color: $light-50;
  color: $light-700;

  &:hover {
    color: $light-700;
    background-color: $light-300;
    border-color: $light-600;
  }

  &:focus {
    outline: 0px;
    color: $light-700;
    background-color: $light-100;
    border-color: $light-800;
  }
  &:focus + .formLabelName,
  &:not(:placeholder-shown) + .formLabelName {
    top: -10px; /* Adjust this value to position the label at the border */
    font-size: 1.2rem;
    color: $light-900;
    border-color: $error-800;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $light-50 inset;
    -webkit-text-fill-color: $light-700;
    &:hover {
      -webkit-box-shadow: 0 0 0 1000px $light-300 inset;
    }
  }

  &__error {
    border-color: $error-800;
    &:hover {
      border-color: $error-800;
    }
    &:focus {
      border-color: $error-800;
    }
  }
}

.errorMessage {
  position: absolute;
  bottom: -16px;
  left: 10px;
  color: $error-800;
  font-size: 1.2rem;
  margin-top: 0.4em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
