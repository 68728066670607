@use "constants/colors" as *;
@mixin buttonTooltip($position: left, $text: "", $bg-color: $light-400) {
  &::before {
    display: none;
    position: absolute;
    top: -10%;
    padding: 0.5em 1em;
    border-radius: 12px;
    background-color: $bg-color;
    font-size: 1.6rem;
    content: $text;
    z-index: 1000;
    @if $position == left {
      left: 80%;
      width: 140%;
    } @else if $position == right {
      right: 80%;
    }
  }

  &:hover::before {
    display: block;
  }
}
@mixin textAreaStyles(
  $padding: 0.5em 1em,
  $width: 100%,
  $height: 150px,
  $font-size: 1.5rem,
  $color: $light-800,
  $bg-color: $light-50,
  $border-radius: 2px,
  $border-color: $light-500,
  $hover-color: $light-700,
  $hover-bg-color: $light-300,
  $hover-border-color: $light-600,
  $focus-color: $light-700,
  $focus-bg-color: $light-100,
  $focus-border-color: $light-600
) {
  padding: $padding;
  width: $width;
  height: $height;
  font-size: $font-size;
  color: $color;
  border-radius: $border-radius;
  background-color: $bg-color;
  border: 1px solid $border-color;
  resize: none;
  color: $light-800;
  &::placeholder {
    color: $light-600;
  }
  &:hover {
    color: $hover-color;
    background-color: $hover-bg-color;
    border-color: $hover-border-color;
  }

  &:focus {
    outline: 0px;
    color: $focus-color;
    background-color: $focus-bg-color;
    border-color: $focus-border-color;
  }
}

@mixin buttonStyles(
  $display: block,
  $margin-left: auto,
  $margin-top: 0.4em,
  $padding: 0.8em 1em,
  $font-size: 1.8rem,
  $font-weight: bold,
  $color: $light-800,
  $border-radius: 12px,
  $background-color: $light-400,
  $border-color: $light-500,
  $transition-duration: 0.4s,
  $hover-bg-color: $light-800,
  $hover-color: $light-400,
  $focus-outline: 2px solid $light-500,
  $focus-border-color: transparent,
  $z-index: 0,
  $cursor: pointer
) {
  display: $display;
  margin-left: $margin-left;
  margin-top: $margin-top;
  padding: $padding;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
  border-radius: $border-radius;
  background-color: $background-color;
  border: 2px solid $border-color;
  transition:
    background-color $transition-duration,
    color $transition-duration;
  overflow: hidden;
  z-index: $z-index;
  cursor: $cursor;

  &:hover {
    background-color: $hover-bg-color;
    color: $hover-color;
  }

  &:focus {
    outline: $focus-outline;
    border: 2px solid $focus-border-color;
  }
}

@mixin closeButtonStyles(
  $position: fixed,
  $top: 15px,
  $right: 15px,
  $padding: 1.2em,
  $border-radius: 50%,
  $border: none,
  $background-color: $light-400,
  $hover-bg-color: $light-500,
  $focus-outline: 2px solid $light-500,
  $transition-duration: 0.3s,
  $z-index: 10000,
  $cursor: pointer
) {
  position: $position;
  top: $top;
  right: $right;
  display: block;
  margin-left: auto;
  margin-bottom: 0.5rem;
  padding: $padding;
  border-radius: $border-radius;
  border: $border;
  background-color: $background-color;
  transition: background-color $transition-duration;
  z-index: $z-index;
  cursor: $cursor;

  &:hover {
    background-color: $hover-bg-color;
  }

  &:focus {
    outline: $focus-outline;
  }
}

@mixin closeIconStyles(
  $position: absolute,
  $top: 50%,
  $left: 50%,
  $transform: translate(-50%, -50%),
  $font-size: 2.6rem,
  $color: $light-800
) {
  position: $position;
  top: $top;
  left: $left;
  transform: $transform;
  font-size: $font-size;
  color: $color;
}
