@use "constants/colors" as *;
@use "scss/mixins" as *;

.dialogBox {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 1.6em 1em;
  .closeBtn {
    @include closeButtonStyles();
  }
  .closeIcon {
    @include closeIconStyles;
  }
  .dialogTitle {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 2rem;
    text-align: center;
    color: $light-800;
    font-weight: bold;
  }
  .textArea {
    @include textAreaStyles();
  }
  .submitBtn {
    @include buttonStyles($font-size: 1.6rem);
  }
}

.btnBox {
  margin: 1.2rem;
  display: flex;
  justify-content: center;
  column-gap: 12px;
  .btnBug,
  .btnFeedback {
    padding: 0.25em 0.35em;
    font-size: 3rem;
    border-radius: 50%;
    background-color: $light-100;
    transition:
      box-shadow 0.3s,
      transform 0.3s;
    cursor: pointer;
    z-index: 10;
  }
  .btnBug {
    position: relative;
    color: $error-400;
    border: none;
    &:focus {
      outline: 2px solid transparent;
    }
    &:hover {
      transform: scale(0.9);
    }
  }
  .btnFeedback {
    position: relative;
    color: $light-800;

    border: none;
    &:focus {
      outline: 2px solid transparent;
    }
    &:hover {
      transform: scale(0.9);
    }
  }
  .btnBugIcon,
  .btnFeedbackIcon {
    background-color: transparent;
  }
}
