@use "../../../constants/colors" as *;

.popupButton {
  border: none;
  background-color: transparent;
  color: $light-800;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  font-size: 1.8rem;
  font-family: "Montserrat", sans-serif;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: $light-400;
    color: $light-900;
  }
}

.changeName {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  justify-content: center; /* Center horizontally */
  align-items: center;
  z-index: 100000;
  &__mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 150px;
    flex-direction: column;
    width: fit-content;
    min-width: 25%;
    height: fit-content;
    border: 1px solid $light-800;
    border-radius: 16px;
    background-color: $light-200;
  }
  &__topContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px 0 16px;
    margin-bottom: 32px;
    border-radius: 16px;
    background-color: $grey-200;
  }
  &__inputContainer {
    padding: 0 48px 0 48px;
  }
  &__saveChangesButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 16px;
    margin-bottom: 32px;
    width: fit-content;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-out;
    background-color: transparent;
    color: $light-800;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    &:hover {
      background-color: $light-400;
      color: $light-900;
    }
  }
  &__close {
    font-size: 48px;
    font-weight: 200;
    cursor: pointer;
  }
}

@media (max-width: 992px) {
  .changeName__mainContainer {
    left: 0px;
  }
}
