@use "../../constants/colors" as *;

.home {
  height: fit-content;
  min-height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: rgb(238, 238, 244);
  background: linear-gradient(
    180deg,
    rgba(238, 238, 244, 1) 0%,
    rgba(228, 228, 238, 1) 30%,
    rgba(212, 212, 228, 1) 70%
  );
}
